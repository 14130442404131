import {
  useRef,
  useState,
  useEffect
} from 'react';
import moment from 'moment';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import { MdSend as SendIcon, MdClose as CloseIcon } from 'react-icons/md';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');

  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register, handleSubmit, setFocus, reset
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_meet_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  return (<div className='w-full flex flex-col'>
    <div className='p-2'>
      <div className="flex justify-between items-center mb-2">
        <p
          style={{
            color: '#4A4A4A'
          }}
          className="font-medium flex justify-between items-center">
          In-call messages
        </p>
        <div />
        <CloseIcon color="#EBEBEB" />
      </div>

      <div style={{
        fontSize: '10px',
        color: '#6F6A6A',
        background: '#F1F3F4'
      }} className="font-medium text-center rounded mb-2">Let everyone send messages</div>

      <div style={{
        fontSize: '8px',
        color: '#6F6A6A',
        background: '#F1F3F4',
        lineHeight: '12px',
        filter: 'blur(0.6px)'
      }} className="font-medium text-center py-2 px-8 rounded cursor-not-allowed">
        Messages can be seen only by people in the call and are deleted when the call ends
      </div>
    </div>

    <ScrollableFeed className='p-4 flex-1'>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </ScrollableFeed>

    <form
      onSubmit={handleSubmit(handleComment)}
      style={{ background: '#F1F3F4' }}
      className='m-2 flex items-center justify-between p-3 rounded-full'>
      <div className='relative'>
        <input
          autoComplete='off'
          {...register('chat_input')}
          style={{ background: '#F1F3F4', fontSize: '10px' }}
          className="w-full h-6 font-medium text-neutral-60 md:leading-5"
          placeholder='Send a message'
        />
        {showInputPulse && isHotspotEnabled && (
          <div className='absolute -top-6 left-24'>
            <Pulse
              isEnabled={isHotspotEnabled}
              onClose={handleHotspotClose}
              tooltip='Please type your response here; it will reflect in the interaction.'
            />
          </div>
        )}
      </div>

      <input type="submit" hidden />

      <button type='submit'>
        <SendIcon />
      </button>
    </form>
  </div>
  );
};

const Message = ({ message }) => (
  <div className='my-4'>
    <div
      style={{ lineHeight: '16px' }}
      className='flex font-medium text-tiny' >
      <p style={{ color: '#222222' }}>{message.name}</p>
      <p className='ml-4' style={{ color: '#666666' }}>
        {moment(message.time).format('hh:mm')}
      </p>
    </div>

    <p
      style={{
        wordWrap: 'anywhere'
      }}
      key={message.id}
      className='font-medium text-xs'>
      {message.message}
    </p>
  </div>
);

export default ChatBox;
