import {
  useRef,
  useState,
  useEffect
} from 'react';
import moment from 'moment';
import Image from '@atoms/Image';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import CloseIcon from '@mui/icons-material/CloseRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { getFontColor } from '@components/Features/talkingTiles/helper';
import { stringToColor } from '../Youtube/ChatBox';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');
  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register, handleSubmit, setFocus, reset
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_zoom_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  return (
    <div className='w-full flex flex-col text-white'>
      <div style={{ background: '#0F0F0F' }} className='py-4 px-5'>
        <div className="flex justify-between items-center">
          <p style={{
            fontSize: '15.75px',
          }} className=" font-bold">
          Meeting Chat
          </p>

          <CloseIcon className='cursor-not-allowed' fontSize='small' />
        </div>
      </div>

      <ScrollableFeed className='p-4 pl-6 mt-0.5 bg-[#0F0F0F] flex-1'>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </ScrollableFeed>

      <div>

        <form
          style={{ background: '#0F0F0F' }}
          onSubmit={handleSubmit(handleComment)}
          className='p-2 rounded-ee-lg'>
          <div className='relative'>
            <input
              autoFocus
              autoComplete='off'
              {...register('chat_input')}
              style={{ color: '#D6D6D6', borderColor: '#525252', borderRadius: '4px', background: '#292929' }}
              className="w-full px-4 py-2.5 border text-sm font-normal text-neutral-60 md:leading-5"
              placeholder='Type message here...'
            />
            {showInputPulse && isHotspotEnabled && (
              <div className='absolute -top-3 left-28'>
                <Pulse
                  isEnabled={isHotspotEnabled}
                  onClose={handleHotspotClose}
                  tooltip='Please type your response here; it will reflect in the interaction.'
                />
              </div>
            )}
          </div>

          <input type="submit" hidden />

          <div className='flex items-center justify-between p-2'>
            <Image className="w-1/2 cursor-not-allowed" src="/images/sandbox/teams-chatbox-footer-icons.png" alt={'teams-chatbox-footer-icons'} />

            <button type='submit'>
              <Image
                alt={'teams-send-icon'}
                className='cursor-pointer'
                src="/images/sandbox/teams-send-button.svg"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const isSelf = (message, activeUserId) => message.userId === activeUserId;

const Message = ({ message }) => {
  const backgroundColor = stringToColor(message.name);

  return (
    <div className='mb-4'>
      {
        isSelf(message, 'user') ? (
          <div className='flex items-end gap-1 justify-end'>
            <div className=' w-fit max-w-60' style={{
              borderRadius: ' 4px 0px 4px 4px',
              background: '#2F2F4A',
              fontSize: '13.891px',
              padding: '5px 16px 7px 16px'
            }}>{message.message}</div>
            <CheckCircleOutlineRoundedIcon fontSize='small' htmlColor='#7F85F5' />
          </div>
        ) : (
          <div className='relative'>
            <div className='absolute top-3 -left-4'>
              <div style={{
                backgroundColor,
                color: getFontColor(backgroundColor)
              }} className='relative rounded-full w-8 h-8 flex items-center justify-center'>
                <p className='text-xs'>{message.name?.at(0)}</p>
                <div className='absolute bottom-0 -right-1 rounded-full border-2 border-black bg-white w-3 h-3'>
                  <div className='bg-white border rounded-full w-2 h-2 border-grey-300' />
                </div>
              </div>
            </div>
            <div
              className='w-fit max-w-60 ml-2'
              style={{
                display: 'flex',
                padding: '8px 16px 10px 16px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                background: '#292929',
                borderRadius: '4px',

              }}>
              <div style={{
                gap: '12.66px',
                padding: '2.5px 0px 2.49px 0px',
              }} className='flex'>
                <div style={{ color: '#D6D6D6' }} className='font-semibold text-xs'>{message.name}</div>
                <div style={{ color: '#ADADAD' }} className='font-normal text-xs'>{moment().format('H:MM A')}</div>
              </div>
              <p style={{
                fontSize: '13.781px',
              }}>{message.message}</p>
            </div>
          </div>
        )
      }

    </div>
  );
};

export default ChatBox;
