import {
  useRef,
  useState,
  useEffect
} from 'react';
import * as yup from 'yup';
import db from '@services/firebase-service';
import { yupValidator } from '@lib/yup-validator';
import {
  query,
  addDoc,
  orderBy,
  onSnapshot,
  collection,
  limitToLast
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { pushDataLayerForEvent } from '@lib/gtag';
import { MdSend as SendIcon, MdOutlineMoreVert as MoreIcon } from 'react-icons/md';
import { GrEmoji as SmileIcon } from 'react-icons/gr';
import { getFontColor } from '@components/Features/talkingTiles/helper';
import { Pulse } from '@atoms/Pulse';
import { useHotspot } from '@lib/hooks/useHotSpot';
import ScrollableFeed from 'react-scrollable-feed';

const ChatBox = ({ streamId, featureId, interactionType, showInputPulse }) => {
  const { isHotspotEnabled, handleHotspotClose } = useHotspot('sandbox:chat-input:hotspot');

  const [messages, setMessages] = useState([]);
  const userName = 'You';
  const activeUserId = 'user';
  const featureType = useRef(null);

  useEffect(() => {
    featureType.current = interactionType;
  }, [interactionType]);

  // eslint-disable-next-line camelcase
  const onComment = async ({ chat_input }) => {
    const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

    const document = {
      upvotes: 0,
      // eslint-disable-next-line camelcase
      message: chat_input,
      time: Date.now(),
      userId: activeUserId,
      name: userName,
      interaction: interactionType
    };

    setMessages((prev) => [...prev, document]);

    await addDoc(rawMessagesRef, document);
  };

  useEffect(() => {
    if (!streamId) return undefined;

    const unSubMessages = onSnapshot(
      query(
        collection(db, 'streams', streamId, 'processedMessages'),
        orderBy('time'),
        limitToLast(20)
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const document = {
              ...change.doc.data(),
              id: change.doc.id
            };

            if (
              document?.name === userName
              && document?.userId === activeUserId
            ) { return; }

            if (featureType.current !== document?.interaction) return;

            setMessages((prev) => [...prev, document]);
          }
        });
      }
    );

    return () => {
      unSubMessages();
    };
  }, [streamId]);

  const {
    register, handleSubmit, setFocus, reset
  } = useForm({
    mode: 'onChange',
    resolver: yupValidator(
      yup.object().shape({
        chat_input: yup.string().required()
      })
    )
  });

  useEffect(() => {
    setFocus('chat_input');
  }, []);

  const handleComment = (data) => {
    pushDataLayerForEvent('sandbox_youtube_chatbox_send_message', { streamId }, false);
    onComment(data);
    reset({ chat_input: '' });
    setFocus('chat_input');
  };

  useEffect(() => {
    setMessages([]);
  }, [featureId]);

  return (
    <div className='w-full flex flex-col'>
      <div className='p-2'>
        <div className="flex justify-between items-center mb-2">
          <p style={{
            color: '#4A4A4A'
          }} className="font-medium flex justify-between items-center">Top Chat</p>
          <div />
          <MoreIcon className='cursor-not-allowed' color="#4A4A4A" />
        </div>
      </div>

      <ScrollableFeed className='p-4 flex-1'>
        {messages.map((message, index) => (
          <Message key={message.id} message={message} />
        ))}
      </ScrollableFeed>

      <div style={{
        boxShadow: '0px -8px 8px 0px rgba(0, 0, 0, 0.05)'
      }}>
        <form
          onSubmit={handleSubmit(handleComment)}
          className='m-2'>
          <div className='relative'>
            <input
              autoComplete='off'
              {...register('chat_input')}
              style={{ fontSize: '10px' }}
              className="w-full h-8 font-medium text-neutral-60 md:leading-5 py-3 border-b-2 border-neutral-20"
              placeholder='Say something ...'
            />
            {showInputPulse && isHotspotEnabled && (
              <div className='absolute -top-4 left-32'>
                <Pulse
                  isEnabled={isHotspotEnabled}
                  onClose={handleHotspotClose}
                  tooltip='Please type your response here; it will reflect in the interaction.'
                />
              </div>
            )}
          </div>

          <input type="submit" hidden />

          <div className='flex justify-between items-center p-2'>
            <SmileIcon className='cursor-not-allowed' />
            <div className='flex'>
              <p className='mr-2 font-medium text-tiny text-neutral-60'>0/200</p>
              <button type='submit'>
                <SendIcon />
              </button>
            </div>
          </div>
        </form>
      </div>

      <div style={{ color: '#C5C5C5' }} className='font-medium text-center p-2 border-t-2 border-neutral-10 cursor-not-allowed'>
        Hide Chat
      </div>
    </div>
  );
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < string.length; i += 1) {
    chr = string.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  // eslint-disable-next-line no-bitwise
  const c = (hash & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return `#${'00000'.substring(0, 6 - c.length)}${c}`;
};

const Message = ({ message }) => {
  const backgroundColor = stringToColor(message.name);
  return (
    <div
      style={{ lineHeight: '16px' }}
      className='flex items-center font-medium text-tiny my-4' >
      <p style={{
        backgroundColor,
        color: getFontColor(backgroundColor)
      }} className='rounded-full h-6 w-8 flex bg-red-900 items-center justify-center text-center mr-2'>{message.name?.at(0)}</p>
      <p style={{ color: '#666666' }}>{message.name}</p>
      <p className='ml-2 w-full'
        style={{
          color: '#444444',
          wordWrap: 'anywhere'
        }}>
        {message.message}
      </p>
    </div>
  );
};

export default ChatBox;
